import { app } from "../..";
import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";

export type ordersByStatusResult = {
    allOrdersByStatus: {}
}

async function action(options: getAllOrdersKeyByStatusUseCaseOptions): Promise<ordersByStatusResult> {
    const allOrderStatuses = await STeams.getAllOrderStatuses();
    let allOrdersKeyByStatus = [];

    await allOrderStatuses.forEach(async (status: any) => {
        let visitedOrders: any = localStorage.getItem(status.orderStatusId);
        const orders = await STeams.getOrdersByPartner({
            partnersKey: options.partnerKey,
            page: 0,
            pageSize: 0,
            statusId: status.orderStatusId
        });

        if (orders) {
            const arr = orders.map(item => ({
                ordersKey: item.order.ordersKey,
                statusId: status.orderStatusId
            }));

            const newArr = arr.reduce((groups, item) => {
                const group = (groups[item.statusId] || []);
                group.push(item.ordersKey);
                groups[item.statusId] = group;
                groups[item.statusId].total = group.length;
                return groups;
            }, {});

            if (Object.entries(newArr).length !== 0)
                allOrdersKeyByStatus.push(Object.assign({}, { ...newArr }));

            if (visitedOrders) {
                localStorage.removeItem(status.orderStatusId);
                visitedOrders = [];
            } else {
                visitedOrders = [];
            }

            if (Object.keys(newArr).length !== 0) {
                let obj = { totalByStatus: orders.length, total: [] };
                newArr[status.orderStatusId].forEach(item => {
                    if (visitedOrders && !visitedOrders.includes(item)) {
                        visitedOrders.push(item);
                        obj.total.push(item);
                        localStorage.setItem(status.orderStatusId, JSON.stringify(obj));
                    }
                });
            }
        }
    })

    return { allOrdersByStatus: allOrdersKeyByStatus };
}

export const getAllOrdersKeyByStatusUseCase: UseCase = {
    type: 'GET_ALL_ORDERS_BY_STATUS',
    run: action
};

interface getAllOrdersKeyByStatusUseCaseOptions {
    partnerKey: string
}

export async function getAllOrdersKeyByStatus(options: getAllOrdersKeyByStatusUseCaseOptions) {
    await app.runUseCase(getAllOrdersKeyByStatusUseCase, options);
}


