import {UseCase} from "../../TypesAndInterfaces/UseCase";
import {UseCaseResult} from "../../TypesAndInterfaces/UseCaseResult";

//You can do enums without strings but hard to debug... you just see number values.
export enum USE_CASE_RESULT_TYPE {
  START = "START",
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  PROGRESS = "PROGRESS"
}

interface UseCaseResultOptions {
  type: string
  useCase?: UseCase,
  data?: any,
  resultType: USE_CASE_RESULT_TYPE,
  rejectionReason?: any
}

export function UseCaseResultFactory( options: UseCaseResultOptions):UseCaseResult{

  return {

    type: options.type,
    useCase: options.useCase,
    data: options.data,
    rejectionReason: options.rejectionReason,
    resultType: options.resultType,

    success(){
      return options.resultType === USE_CASE_RESULT_TYPE.SUCCESS;
    },

    failure(){
      return options.resultType === USE_CASE_RESULT_TYPE.FAILURE;
    },

    start(){
      return options.resultType === USE_CASE_RESULT_TYPE.START;
    },

    progress(){
      return options.resultType === USE_CASE_RESULT_TYPE.PROGRESS;
    }

  };

}
