import { useSelector } from "react-redux";
import { RootState } from "../Application/Application";
import Button from "./Button";
import { showOrdersByStatus } from "../Application/UseCases/showOrdersByStatus";
import { signOut } from "../Application/UseCases/Login/signOut";
import { orderBy } from 'lodash';
import { Link, withRouter, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "../Utility/isMobile";
import useOutsideClick from "../Utility/useOutsideClick";
import { setActiveSportId } from "../Application/UseCases/setActiveSportId";
import { setActivePath } from "../Application/UseCases/setActivePath";
import { getAllOrdersKeyByStatus } from "../Application/UseCases/getAllOrdersKeyByStatus";
import { STeams } from "../ExternalAPI/STeams";

// Styles
import styles from "./MainNavigation.module.scss";
import styled from "styled-components";

const Bars = ({ open, setOpen }) => {
  return (
    <StyledNavIcons open={open} onClick={() => setOpen(!open)}>
      <div className={styles.navIcons}>
        <i className="fas fa-bars"></i>
        {open ? <i className="fas fa-times"></i> : null}
      </div>
    </StyledNavIcons>
  )
}

function MainNavigation() {
  const orderStatuses = useSelector((state: RootState) => state.App.allOrderStatuses);
  const currentOrderStatusId = useSelector((state: RootState) => state.Order.currentStatusId);
  const currentSportId = useSelector((state: RootState) => state.Gui.activeSportId);
  const partner: any = useSelector((state: RootState) => state.App.partnerData);
  const sportId = partner.PartnerSport.find(g => g.sport.id == currentSportId)?.id;
  const partnerName = partner.Partner?.name;
  const partnersKey = useSelector((state: RootState) => state.Login.partnersKey);
  let orderStatusesInOrder: any[] = orderBy(orderStatuses, ['displayOrder'], ['asc']);
  let matchMedia = useMedia("(max-width: 850px)");
  const mobile = isMobile();
  const [searchText, setSearchText] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(true);
  const [open, setOpen] = useState((mobile || matchMedia) ? false : true);
  const navigationMenuRef = useRef();
  const searchInput = useRef(null);
  const history = useHistory();
  const [count, setCount] = useState<number>(0);

  function useMedia(query) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
      let media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      let listener = () => setMatches(media.matches);
      media.addEventListener("change", () => {
        listener();
      });

      return () => media.removeEventListener("change", () => {
        listener();
      });
    }, [query]);

    return matches;
  }

  useEffect(() => {
    const timer = setInterval(() => validate(), 230);
    return () => clearInterval(timer);
  });

  useEffect(() => {
    if (count === 0) {
      checkForNewOrders();
      showOrdersByStatus({ statusId: currentOrderStatusId, partnersKey });
    }
  }, [count]);

  useEffect(() => {
    const overlay = document.getElementById('root').children[0].children[0];
    if ((open && matchMedia) || (open && mobile)) {
      // @ts-ignore
      overlay.style.display = 'block';
    } else {
      // @ts-ignore
      overlay.style.display = 'none';
    }
  }, [open]);

  const validate = () => {
    setCount((prev) => prev < 60 ? prev + 1 : 0);
  }

  function checkForNewOrders() {
    return new Promise((resolve, request) => {
      setTimeout(() => resolve(getAllOrdersKeyByStatus({ partnerKey: partnersKey })), 1000);
    });
  }

  const ordersS = orderStatuses.reduce((acc: any, value: any, index: number) => {
    let visitedOrders: any = localStorage.getItem("visitedOrders");
    let ordersByStatus: any = localStorage.getItem(value.orderStatusId);
    let matches = [];

    if (ordersByStatus && visitedOrders) {
      visitedOrders = visitedOrders.split(",");
      ordersByStatus = JSON.parse(ordersByStatus);
      matches = visitedOrders.filter(e => ordersByStatus.total?.indexOf(e) !== -1);
    }

    let totalOrders =
      Array.isArray(ordersByStatus?.total) ? ordersByStatus?.total.length : (ordersByStatus && JSON.parse(ordersByStatus)?.total.length);

    // @ts-ignore
    acc[value.orderStatusId] = {
      badge: totalOrders - matches.length
    };

    return acc;
  }, {});

  useOutsideClick(navigationMenuRef, () => {
    if (document.activeElement !== searchInput.current) {
      setOpen(false);
    }
  });

  function handleManagePricingClick(sportId) {
    setActivePath({ activePath: "/prices" });
    setActiveSportId({ activeSportId: sportId });
  }

  function handleClick(orderStatusId: string) {
    setActivePath({ activePath: "/prders" });
    showOrdersByStatus({ statusId: orderStatusId, partnersKey });
  }

  function handleChange(searchText: string) {
    setSearchText(searchText);
    if (searchText.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  function searchOrder(searchText: string) {
    let orderData: any;
    let location: {};

    STeams.getOrdersByPartner({ partnersKey: partnersKey })
      .then((orders) => {
        orderData = orders.find((p: any) => {
          return p.order.ordersKey === searchText;
        });

        location = {
          pathname: `/orderDetails/${searchText}`,
          state: { orderData: orderData }
        };

        history.push(location);
      });
  }

  function logOut() {
    signOut();
  }

  function getNumber(statusId: string) {
    let item = JSON.parse(localStorage.getItem(statusId));
    let number = undefined;

    if (item)
      number = item.totalByStatus

    return number;
  }

  return <>
    <div className={styles.MainNavUserWelcomeMobile}
      ref={navigationMenuRef}>
      <Bars
        open={!matchMedia || open}
        setOpen={setOpen} />
      Hello{partnerName && ", " + partnerName.trim()}!
    </div>
    <div className={styles.itemsMenu}>
      <StyledMainNavigation open={!matchMedia || open}>
        <div className={styles.MainNav}>
          <div className={styles.MainNavUserWelcome}>Hello{partnerName && ", " + partnerName.trim()}!
            <span onClick={() => logOut()}>Sign out</span>
          </div>
          <div className={styles.MainNavHeader}><i className="fas fa-star"></i>PRICING</div>
          {
            partner.PartnerSport.map((sport) => {
              const hasChildren = partner.PartnerGrayGood.filter(g => g.sport.id == sport.sport.id).length > 0;
              return hasChildren && <div key={sport.id}>
                <Link to={{
                  pathname: '/prices'
                }} className={styles.noneDecaration}>
                  <Button
                    className={`${sport.id === sportId ? `${styles.sideNavItemsActivePrices}` : `${styles.sideNavItemsPrices}`}`}
                    key={sport.id}
                    theme="noStyle"
                    label={sport.name}
                    active={sport.id === sportId}
                    onClick={() => handleManagePricingClick(sport.sport.id)}
                  />
                </Link>
              </div>
            })
          }
          <div className={styles.MainNavHeader}><i className="fas fa-square"></i>ORDERS</div>
          {
            orderStatusesInOrder && (orderStatusesInOrder.map((orderStatus: any) => {
              return <div key={orderStatus.orderStatusId}>
                <Link to={{
                  pathname: '/orders'
                }} className={styles.noneDecaration}>
                  <Button
                    badge={ordersS[orderStatus.orderStatusId].badge}
                    className={`${orderStatus.orderStatusId === currentOrderStatusId
                      ? `${styles.sideNavItemsActive}`
                      : `${styles.sideNavItems}`}`}
                    key={orderStatus.orderStatusId}
                    theme="noStyle"
                    label={orderStatus.displayNames.partner +
                      (localStorage.getItem(orderStatus.orderStatusId) ? " (" + getNumber(orderStatus.orderStatusId) + ")" : "")}
                    active={orderStatus.orderStatusId === currentOrderStatusId}
                    onClick={() => handleClick(orderStatus.orderStatusId)}
                  ></Button>
                </Link>
              </div>
            }))
          }
          <div className={styles.searchContainer}>
            <div className={styles.MainNavHeader}>LOOKUP BY KEY</div>
            <input
              type="text"
              value={searchText}
              ref={searchInput}
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Search..."
            />
            <Button
              faIconCls="fa-search"
              label="LOOKUP ORDER"
              theme="blue"
              disabled={disabled}
              onClick={() => { searchOrder(searchText) }}>
            </Button>
          </div>
          <div className={styles.divider}></div>
          <Button
            faIconCls="fa-sign-out-alt"
            label="LOG-OUT"
            theme="blue"
            onClick={() => { logOut() }}>
          </Button>
        </div>
      </StyledMainNavigation>
    </div>
  </>
}

const StyledMainNavigation = styled.nav`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
`
const StyledNavIcons = styled.button`
  font-size: 1.5em;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  color: #fff;

  &:focus {
    outline: none;
  }
`

export default withRouter(MainNavigation);