import { useState } from "react";
import { useSelector } from "react-redux"
import Button from '../Components/Button'
import { RootState } from "../Application/Application";
import { requestPhoneCode } from "../Application/UseCases/Login/requestPhoneCode";
import { verifyPhoneCode } from "../Application/UseCases/Login/verifyPhoneCode";

// Styles
import styles from './Login.module.scss';

function stringStartsWith (str: string, search: string): boolean {
  str = str.toLowerCase();
  search = search.toLowerCase();

  return str.substring(0, search.length) === search;
}

function cleanPhoneNumber (phone: string): string {
  const countryCode = '+1';

  if (stringStartsWith(phone, countryCode)) {
    // Remove country code
    phone = phone.substring(countryCode.length, phone.length);
  }

  // Numbers only
  phone = phone.replace(/\D/g,'');

  return countryCode + phone;
}

function Login() {
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState<string>("");
  const requestInProgress = useSelector((state: RootState) => state.Login.requestInProgress);
  const verifyInProgresss = useSelector((state: RootState) => state.Login.verifyInProgress);
  const requestSucceeded = useSelector((state: RootState) => state.Login.requestSucceeded);
  const verifySucceeded = useSelector((state: RootState) => state.Login.verifySucceeded);
  const disableRequestButton = phoneNumber.length === 0 || requestInProgress || requestSucceeded;
  const disableVierfyButton = phoneCode.length === 0 || verifyInProgresss || verifySucceeded;
  const errorMessage = useSelector((state: RootState) => state.Login.errorMessage);

  const handleSendLoginRequest = () => {
    requestPhoneCode({ phone: phoneNumber });
  }

  const handleValidatePhoneCode = () => {
    const number = cleanPhoneNumber(phoneNumber);
    verifyPhoneCode({ phone: number, code: phoneCode });
  }

  return <div className={styles.loginWrapper}>
    <div className={styles.loginContainer}>
      {(requestSucceeded || verifyInProgresss)
        ?
        <><div className={styles.title}>Enter phone code</div>
          <input
            type="text"
            value={phoneCode}
            onChange={(e) => setPhoneCode(e.target.value)}
            placeholder="Phone Code"
          />
          <Button
            className={styles.loginBtn}
            label="VERIFY PHONE CODE"
            theme="blue"
            disabled={disableVierfyButton}
            onClick={() => { handleValidatePhoneCode() }}>
          </Button></>
        :
        <><div className={styles.title}>Log in with your phone number</div>
          <input
            className={`${(errorMessage && phoneNumber) && styles.invalid}`}
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
          />
          <Button
            className={styles.loginBtn}
            label="REQUEST PHONE CODE"
            theme="blue"
            disabled={disableRequestButton}
            onClick={() => { handleSendLoginRequest() }}>
          </Button></>
      }
      <div className={styles.errorMsg}>{errorMessage}</div>
    </div>
  </div>
}

export default Login;