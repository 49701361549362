import { useSelector } from "react-redux";
import { RootState } from "../../Application/Application";
import { getImageURL } from '../../Utility/image'
import { Form } from 'react-bootstrap';
import { changeGrayGoodPrice } from "../../Application/UseCases/changeGrayGoodPrice";
import Button from "../Button";
import { useEffect, useRef, useState } from "react";

// Styles
import styles from "./GrayGoods.module.scss";

interface Props { }

const GrayGoods = (props: Props) => {
  const partner: any = useSelector((state: RootState) => state.App.partnerData);
  const updatingPrice: boolean = useSelector((state: RootState) => state.App.updatingPrice);
  const updatingPriceFailed: boolean = useSelector((state: RootState) => state.App.updatingPriceFailed);
  const activeSportId: any = useSelector((state: RootState) => state.Gui.activeSportId);
  const sportName = partner.PartnerSport.find( g => g.sport.id === activeSportId )?.name;
  const activeGrayGoods = partner.PartnerGrayGood.filter(g => g.sport.id === activeSportId);
  const btn: any = useRef([]);
  const [currentElement, setCurrentElement] = useState(null);

  useEffect(() => {
    btn.current = btn.current.slice(0, activeGrayGoods.length);
  }, [activeGrayGoods]);

  useEffect(() => {
    if (updatingPrice && !updatingPriceFailed) {
      updatedBtn();
    }
    if (updatingPriceFailed && !updatingPrice) {
      updatedBtnFailed()
    }
  }, [updatingPrice, updatingPriceFailed]);

  const onHandleUpdate = (btn: any, grayGood: any, updatingPrice: boolean) => index => {
    changeGrayGoodPrice({
      partnersKey: grayGood.partnersKey
      , sportAssetsKey: grayGood.sport.sportAssetsKey
      , id: grayGood.grayGood.id
      , price: grayGood.price
    });

    setCurrentElement(btn?.current[index]);
  }

  function updatedBtn() {
    const el = currentElement.children[0];
    el.style.display = "flex";
    el.style.backgroundColor = "#198754";
    el.children[1].style.display = "none";
    el.children[0].classList.add('fa', 'fa-check');
    el.children[0].style.transform = "scale(1.3)";

    const timer = setTimeout(() => {
      el.style.backgroundColor = "#1f76dc";
      el.children[0].classList.remove('fa', 'fa-check');
      el.children[0].style.transform = "scale(1)";
      el.children[1].style.display = "flex";   
    }, 1000);
    return () => clearTimeout(timer);
  }

  function updatedBtnFailed() {
    const el = currentElement.children[0];
    el.style.display = "flex";
    el.style.backgroundColor = "#dc3545";
    el.children[1].style.display = "none";
    el.children[0].classList.add('fa', 'fa-times');
    el.children[0].style.transform = "scale(1.3)";

    const timer = setTimeout(() => {
      el.style.backgroundColor = "#1f76dc";
      el.children[0].classList.remove('fa', 'fa-times');
      el.children[0].style.transform = "scale(1)";
      el.children[1].style.display = "flex";   
    }, 1000);
    return () => clearTimeout(timer);
  }

  return (<div className={styles.grayGoodsContainer}>
    <div className={styles.header}>
      <div className={styles.headerText}>Pricing</div>
      <span className={styles.sport}>{sportName}</span>
    </div>
    <div className={styles.list}>
      {
        activeGrayGoods.map((grayGood, i) => {
          const publishedThumbUrl = grayGood && grayGood.artRefs && grayGood.artRefs.publicDocId && getImageURL(grayGood.artRefs.publicDocId, 120, 120);
          return <div className={styles.item} key={grayGood.id}>
            <img className={styles.grayGoodImg} src={publishedThumbUrl} alt="GrayGood"></img>
            <span className={styles.name}>{grayGood.name}</span>
            <div className={styles.wrapInput}><span>$</span>
              <Form.Control
                required type="number"
                placeholder="Gray good Price"
                min="0.01"
                step="0.01"
                maxLength={6}
                onChange={(e) => {
                  grayGood.price = e.target.value;
                }}
                defaultValue={grayGood.price}
              />
            </div>
            <div className={styles.divider} />
            <div className={styles.btnContainer} ref={el => btn.current[i] = el}  >
              <Button
                faIconCls=""
                label="UPDATE PRICE"
                className={styles.updatePriceBtn}
                key={grayGood.id}
                active={true}
                theme="blue"
                onClick={() => {
                  onHandleUpdate(btn, grayGood, updatingPrice)(i);
                }}
              />
            </div>
          </div>
        })
      }
    </div>
  </div>)
}

export default GrayGoods;