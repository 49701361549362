import { UseCase } from "../TypesAndInterfaces/UseCase";
import { app } from "../../index";

interface setLastPageUseCaseOptions {
    statusId: string,
    page: number
  }

export type lastPageByStatusResult = {
    statusId: string;
    page: number;
  }
  

async function action(options: setLastPageUseCaseOptions): Promise<lastPageByStatusResult> {
    const lastPage: lastPageByStatusResult =  { statusId: options.statusId, page: options.page };

    return { ...lastPage };
}

export const setLastPageUseCase: UseCase = {
    type: 'setLastPage',
    run: action
};

export async function setLastPageByStatus(options: setLastPageUseCaseOptions) {
    await app.runUseCase(setLastPageUseCase, options);
}