import React, { useEffect, useState } from "react";
import { isoStringToPrettyDateAndTime } from "../../Utility/Date";
import { useSelector } from "react-redux";
import { RootState } from "../../Application/Application";
import Button from "../Button";
import { OrderStatusId } from "../../Application/Domain/Order";
import { Link, withRouter } from "react-router-dom";
import useTable from "../Pagination/useTable";
import Pagination from "../Pagination/Pagination";
import { setLastPageByStatus } from "../../Application/UseCases/setLastPage";
import { onApproveAndPayClick, onPayClick, onReadyClick } from "./OrderActions";

// Styles
import styles from "./Orders.module.scss";

interface OrdersProps {
  orders: Array<any>,
  currentOrderStatusId?: string,
  lastPageByStatus?: any,
  partnersKey?: string
}

const OrdersTable = (props: OrdersProps) => {
  const partnersKey = props.partnersKey;
  const orders = props.orders;
  const lastPageByStatus = props.lastPageByStatus;
  const rowsPerPage = 10;
  const [page, setPage] = useState<number>(1);
  const sortType = {
    // @ts-ignore
    fn: (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
  };
  const { items, range } = useTable(orders, page, rowsPerPage, sortType);
  let visitedOrders: any = localStorage.getItem("visitedOrders");

  const viewedOrder = (orderKey: any) => {
    if (visitedOrders) {
      visitedOrders = visitedOrders.split(",");
    } else {
      visitedOrders = [];
    }

    if (!visitedOrders.includes(orderKey)) {
      visitedOrders.push(orderKey);
      localStorage.setItem("visitedOrders", visitedOrders.toString());
    }
  };

  useEffect(() => {
    if (props.currentOrderStatusId === lastPageByStatus?.statusId)
      setPage(lastPageByStatus?.page);
    else
      setPage(1);
  }, [props.currentOrderStatusId]);

  useEffect(() => {
    setLastPageByStatus({ statusId: props.currentOrderStatusId, page: page })
  }, [page]);

  return <>
    <table className={styles.ordersTable}>
      <thead>
        <tr>
          <th>Order</th>
          <th>Created</th>
          <th>Products</th>
          <th>Organization</th>
          <th>Cost</th>
          <th>Retail</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((orderData: any) => (
            <tr key={orderData.order.ordersKey}>
              <td data-label="Order">
                <Link
                  onClick={() => viewedOrder(orderData.order.ordersKey)}
                  to={{
                    pathname: `/orderDetails/${orderData.order.ordersKey}`
                    , state: {
                      orderData: orderData
                    }
                  }} >
                  {orderData.order.ordersKey} {visitedOrders?.includes(orderData.order.ordersKey) ? null : <span className={styles.unopened}><i className="fas fa-circle"></i></span>}
                </Link>
              </td>
              <td data-label="Created">{isoStringToPrettyDateAndTime(orderData.createdOn)}</td>
              <td data-label="Products">
                {
                  orderData.orderSnippetData.products?.map((productSnippetData: any) => {
                    return <React.Fragment key={productSnippetData.savedDocId}>
                      <div key={productSnippetData.savedDocId}>{`${productSnippetData.designStyleName} - ${productSnippetData.grayGoodName}`}</div>
                    </React.Fragment>
                  })
                }
              </td>
              <td data-label="Organization">{orderData.orderSnippetData?.coachContactInfo?.organizationName}</td>
              <td data-label="Cost">{isNaN(orderData.orderSnippetData.costTotal) ? "--" : "$" + parseFloat(orderData.orderSnippetData.costTotal.toFixed(2))}</td>
              <td data-label="Retail">{isNaN(orderData.orderSnippetData.retailTotal) ? "--" : "$" + parseFloat(orderData.orderSnippetData.retailTotal.toFixed(2))}</td>
              { (props.currentOrderStatusId === OrderStatusId.ApprovedPendingPayment 
              || props.currentOrderStatusId === OrderStatusId.PendingReview 
              || props.currentOrderStatusId === OrderStatusId.ShippedToPartner) &&
              <td className={styles.tdBtn}>
                {(props.currentOrderStatusId === OrderStatusId.ApprovedPendingPayment) && <Button theme="green" label="Pay" faIconCls="fa-credit-card" handler={() => onPayClick(orderData.order.ordersKey, props.currentOrderStatusId, partnersKey)} />}
                {(props.currentOrderStatusId === OrderStatusId.PendingReview) && <Button theme="green" label="Approve and Pay" faIconCls="fa-thumbs-up" handler={() => onApproveAndPayClick(orderData.order.ordersKey, props.currentOrderStatusId, partnersKey)} />}
                {(props.currentOrderStatusId === OrderStatusId.ShippedToPartner) && <Button theme="green" label="Mark as Ready!" faIconCls="fa-image" handler={() => onReadyClick(orderData.order.ordersKey, props.currentOrderStatusId, partnersKey)} />}
              </td>  
              }
            </tr>
          ))
        }
      </tbody>
    </table> {orders.length > 10 ? <Pagination range={range} slice={items} setPage={setPage} page={page} /> : null}</>
}

function Orders() {
  const orders = useSelector((state: RootState) => state.Order.orders);
  const currentOrderStatusId = useSelector((state: RootState) => state.Order.currentStatusId);
  const lastPageByStatus = useSelector((state: RootState) => state.Order.lastPageByStatus);
  const partnersKey = useSelector((state: RootState) => state.Login.partnersKey);

  return <React.Fragment>
    <div className={styles.header}>
      <div className={styles.headerText}>Orders</div>
    </div>
    <OrdersTable orders={orders} currentOrderStatusId={currentOrderStatusId} lastPageByStatus={lastPageByStatus} partnersKey={partnersKey} />
  </React.Fragment>
}

export default withRouter(Orders);

