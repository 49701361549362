import { UseCaseResult } from "../TypesAndInterfaces/UseCaseResult";
import { initOrderDataResult, initOrderDataUseCase } from "../UseCases/Init/initOrderData";
import { showOrdersByStatusUseCase } from "../UseCases/showOrdersByStatus";
import { MainContentAreas } from "../TypesAndInterfaces/MainContentAreas";
import { initPartnerDataUseCase } from "../UseCases/Init/initPartnerData";
import { changeGrayGoodPriceUseCase } from "../UseCases/changeGrayGoodPrice";

export interface AppState {
  initComplete: boolean;
  allOrderStatuses: [];
  defaultOrderStatusId?: string;
  mainView: MainContentAreas,
  partnerData: {
    Partner: any[]
    , PartnerBranding: any[]
    , PartnerSport: any[]
    , PartnerGrayGood: any[]
    , PartnerDesignStyle: any[]
  },
  updatingPrice: boolean,
  updatingPriceFailed: boolean
}

const defaultAppReducerData: AppState = {
  initComplete: false,
  allOrderStatuses: [],
  defaultOrderStatusId: undefined,
  mainView: MainContentAreas.Orders,
  partnerData: {
    Partner: []
    , PartnerBranding: []
    , PartnerSport: []
    , PartnerGrayGood: []
    , PartnerDesignStyle: []
  },
  updatingPrice: false,
  updatingPriceFailed: false
};

export const AppReducer = function (state: AppState = { ...defaultAppReducerData }, useCaseResult: UseCaseResult) {
  state = Object.assign(state, { // Ensure we return a new state
    actionResult: useCaseResult
  });

  switch (useCaseResult.type) {
    case initOrderDataUseCase.type:
      // Want to have all use cases have a getResultData with defined type (so we don't have to know here but IDE recognizes)
      // An extra thing we need to know about... aside from use case we're "handling"
      const result: initOrderDataResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          initComplete: true,
          allOrderStatuses: result.allOrderStatuses,
          defaultOrderStatus: result.defaultStatusId
        });
      }
      break;

    case showOrdersByStatusUseCase.type:
      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          mainView: MainContentAreas.Orders
        });
      }
      break;

    case initPartnerDataUseCase.type:
      const partnerData = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          partnerData: {
            Partner: partnerData.Partner[0]
            , PartnerBranding: partnerData.PartnerBranding[0]
            , PartnerSport: partnerData.PartnerSport
            , PartnerGrayGood: partnerData.PartnerGrayGood
            , PartnerDesignStyle: partnerData.PartnerDesignStyle
          }
        });
      }
      break;

    case changeGrayGoodPriceUseCase.type:
      if (useCaseResult.failure()) {
        return Object.assign({}, state, {
          updatingPrice: false,
          updatingPriceFailed: true
        });
      }

      if (useCaseResult.start()) {
        return Object.assign({}, state, {
          updatingPrice: true,
          updatingPriceFailed: false
        });
      }

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          updatingPrice: false,
          updatingPriceFailed: false
        });
      }
      break;


    default:
      return state;
  }

  return state;
};


