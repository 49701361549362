import { STeams } from "../../../ExternalAPI/STeams";
import { UseCase } from '../../TypesAndInterfaces/UseCase';
import { getPartnerSessionData } from "./helper/getPartnerSessionData"
import {app} from "../../../index";

declare const Cx ;

export interface VerifyPhoneCodeUseCaseOptions {
  code: string;
  phone: string;
}

export const verifyPhoneCodeUseCase: UseCase = {
  type: 'VERIFY_PHONE_CODE',

  run: async ( options: VerifyPhoneCodeUseCaseOptions ) => {
    // TODO: Enter wrong code to test 400 results
    const verifyPhoneResult = await STeams.verifyPhoneCode({ code: options.code, phone: options.phone });

    if(verifyPhoneResult.verified) {
      localStorage.setItem('steamspartner.sessionToken', verifyPhoneResult.sessionToken);

      const coachSessionData = await getPartnerSessionData(verifyPhoneResult.sessionToken);
      return coachSessionData;
    }
    else {
      return Promise.reject({
        errorMessage: verifyPhoneResult.errorMessage
      });
    }
  }
};

export function verifyPhoneCode( options: VerifyPhoneCodeUseCaseOptions ) {
  app.runUseCase( verifyPhoneCodeUseCase, options );
}
