import { UseCaseResult } from "../TypesAndInterfaces/UseCaseResult";
import { setActiveSportIdUseCase } from "../UseCases/setActiveSportId";
import { initPartnerDataUseCase  } from "../UseCases/Init/initPartnerData";
import { setActivePathUseCase
 } from "../UseCases/setActivePath";

export interface GuiState {
  activeSportId: string,
  activePath: string
}

const defaultGuiReducerData: GuiState = {
  activeSportId: undefined,
  activePath: '/orders'
};

export const GuiReducer = function (state: GuiState = { ...defaultGuiReducerData }, useCaseResult: UseCaseResult) {
  state = Object.assign(state, { // Ensure we return a new state
    actionResult: useCaseResult
  });

  switch (useCaseResult.type) {

    case initPartnerDataUseCase.type:
      const partnerData = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          activeSportId: partnerData.PartnerSport[0]?.sport?.id
        });
      }
      break;
    case setActiveSportIdUseCase.type:
      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          activeSportId: useCaseResult.data.activeSportId
        });
      }
      break;

    case setActivePathUseCase.type:
      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          activePath: useCaseResult.data.activePath
        });
      }
      break;

    default:
      return state;
  }

  return state;
};

