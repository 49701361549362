import { app } from "../..";
import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";

export type coachContactInfo = {
  id: string;
  name: string,
  email: string,
  phone: string,
  organizationName: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string
}

async function action(options: getCustomerInfoReadByKeyUseCaseOptions): Promise<coachContactInfo> {
  const result = await STeams.getCoachDataReadByKeyUrl(options.coachesKey);
  const contactInfo: coachContactInfo = result?.items.CoachContactInfo.find(function (c) {
    return c.id === options.customerInfoKey;
  });

  return { ...contactInfo };
}

export const getCustomerInfoReadByKeyUseCase: UseCase = {
  type: 'GET_CUSTOMER_INFO_READ_BY_KEY',
  run: action
};

interface getCustomerInfoReadByKeyUseCaseOptions {
  coachesKey: string,
  customerInfoKey: string
}

export async function getCustomerInfoReadByKey(options: getCustomerInfoReadByKeyUseCaseOptions) {
  await app.runUseCase(getCustomerInfoReadByKeyUseCase, options);
}


