import { UseCase } from '../../TypesAndInterfaces/UseCase';
import { app } from "../../../index";
import { STeams } from "../../../ExternalAPI/STeams";
import { find, first } from 'lodash';

export type initOrderDataResult = {
  orders: [];
  page: number;
  pageSize: number;
  defaultStatusId: string;
  allOrderStatuses: [];
}

export interface initOrderDataOptions {
  partnersKey: string
}

async function initFn(options: initOrderDataOptions): Promise<initOrderDataResult> {
  const allOrderStatuses = await STeams.getAllOrderStatuses();
  
  if (!allOrderStatuses)
    throw Error('Could not load order statuses.');

  let defaultOrderStatus = find(allOrderStatuses, { orderStatusId: 'NeedsReview' });
  if (!defaultOrderStatus)
    defaultOrderStatus = first(allOrderStatuses);

  const orders = await STeams.getOrdersByPartner({
    partnersKey: options.partnersKey,
    page: 0,
    pageSize: 0,
    statusId: defaultOrderStatus.orderStatusId
  });

  return {
    orders: orders,
    page: 0,
    pageSize: 0,
    defaultStatusId: defaultOrderStatus.orderStatusId,
    allOrderStatuses: allOrderStatuses
  };
}

export const initOrderDataUseCase: UseCase = {
  type: 'initOrderData',
  run: initFn
};

export async function initOrderData(options: initOrderDataOptions) {
  await app.runUseCase(initOrderDataUseCase, options);
}