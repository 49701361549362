import {UseCase} from "../TypesAndInterfaces/UseCase";
import {app} from "../../index";

export type setActiveSportIdUseCaseOptions = {
  activeSportId: string;
}

export type setActiveSportIdUseCaseResult = {
  activeSportId: string;
}

async function action(options: setActiveSportIdUseCaseOptions):Promise<setActiveSportIdUseCaseResult> {

  return { activeSportId: options.activeSportId };
}

export const setActiveSportIdUseCase:UseCase = {
  type: 'setActiveSportId',
  run: action
};

export async function setActiveSportId(options: setActiveSportIdUseCaseOptions){
  await app.runUseCase(setActiveSportIdUseCase, options);
}
