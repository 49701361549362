import { loadStripe } from "@stripe/stripe-js";
import { Network } from "../../Utility/Network";
import { getAllOrdersKeyByStatus } from "../../Application/UseCases/getAllOrdersKeyByStatus";
import { showOrdersByStatus } from "../../Application/UseCases/showOrdersByStatus";
import { OrderStatusId } from "../../Application/Domain/Order";
import { renderInModal } from "../Modal";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const promise = loadStripe("pk_test_51IQfoUGLfQqxxYUIokB9YgfG0e4US3OCfAgF8HlbgDZObSCy8BJMpyVoQogW3i1IGsagKwFow2mropdxlHv0bMPv00ftjPeZWc");
const API_URL = process.env.REACT_APP_STEAMS_API_URL || 'https://ddbackend-staging.azurewebsites.net/api';

export const onApproveAndPayClick = async (orderKey: string, currentOrderStatusId: string, partnersKey: string) => {
    const data = await Network.post({
        endpoint: `${API_URL}/orders/changeStatus`,
        payload: { ordersKey: orderKey, newOrderStatusId: OrderStatusId.ApprovedPendingPayment }
    });

    renderInModal(<Elements stripe={promise} >
        <CheckoutForm orderKey={orderKey} />
    </Elements>)

    updateTotalOrdersByStatus(currentOrderStatusId, partnersKey);
};

export const onPayClick = (orderKey: string, currentOrderStatusId: string, partnersKey: string) => {
    renderInModal(<Elements stripe={promise} > <CheckoutForm orderKey={orderKey} /> </Elements>)
    updateTotalOrdersByStatus(currentOrderStatusId, partnersKey);
};

export const onReadyClick = async (orderKey: string, currentOrderStatusId: string, partnersKey: string) => {
    const data = await Network.post({
        endpoint: `${API_URL}/orders/changeStatus`,
        payload: { ordersKey: orderKey, newOrderStatusId: OrderStatusId.Complete }
    });

    updateTotalOrdersByStatus(currentOrderStatusId, partnersKey);

    return data;
};

const updateTotalOrdersByStatus = (currentOrderStatusId: string, partnersKey: string) => {
    getAllOrdersKeyByStatus({ partnerKey: partnersKey });
    showOrdersByStatus({ statusId: currentOrderStatusId, partnersKey });
}