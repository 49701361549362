import React from 'react';
import { render } from 'react-dom';
import Button from './Button';

// Styles
import './Modal.css';

interface Props {
  bodyCls?: string;
  children: any;
  //closeHandler: any;
  cls?: string;
  side: string;
  title?: string;
  unmountCb?: Function;
}

export const renderInModal = (child: React.ReactElement) => {
  render(<Modal side="center">
    {child}
  </Modal>, document.getElementById('modalRoot'));
};

export const closeModal = () => {
  //@ts-ignore
  render(null, document.getElementById('modalRoot'));
};

export const Modal = (props: Props) => {
  const animationCls = `modal-show-${props.side}`;
  const cls = `modal modal-${props.side} ${animationCls} ${props.cls || ''}`;
  const bodyCls = `body ${props.bodyCls || ''}`;
  const closeHandler = () => closeModal();

  return <div className="modal-wrap">
    <div className="modal-overlay" onClick={closeHandler}></div>
    <div className={cls}>
      <div className="header partner-color2">
        <div className="title">{props.title}</div>
        <Button onClick={closeHandler} className="close-tool" iconCls="fa-times" />
      </div>
      <div className={bodyCls}>
        {props.children}
      </div>
    </div>
  </div>;
};

//export default Modal;