import {UseCase} from "../TypesAndInterfaces/UseCase";
import {app} from "../../index";

export type setActivePathUseCaseOptions = {
  activePath: string;
}

export type setActivePathUseCaseResult = {
  activePath: string;
}

async function action(options: setActivePathUseCaseOptions):Promise<setActivePathUseCaseResult> {

  return { activePath: options.activePath };
}

export const setActivePathUseCase:UseCase = {
  type: 'setActivePath',
  run: action
};

export async function setActivePath(options: setActivePathUseCaseOptions){
  await app.runUseCase(setActivePathUseCase, options);
}
