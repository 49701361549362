import { UseCase } from '../../TypesAndInterfaces/UseCase';
import { app } from "../../../index";
import { STeams } from "../../../ExternalAPI/STeams";

export type partner = {
    modelType: "Partner"
    id?: string
    partnersKey: string
    email: string
    name: string
    cadworxSessionId: string
    archived: boolean
}

interface initPartnerDataUseCaseOptions {
    partnersKey: string
}

async function action(options: initPartnerDataUseCaseOptions): Promise<partner> {
    const partner: partner = await STeams.getPartnerByKey(options.partnersKey);
    return { ...partner };
}

export const initPartnerDataUseCase: UseCase = {
    type: 'INIT_PARTNER_DATA',
    run: action
};

export async function initPartnerData(options: initPartnerDataUseCaseOptions) {
    await app.runUseCase(initPartnerDataUseCase, options);
}

