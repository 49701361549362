import MainNavigation from "./Components/MainNavigation";
import { useSelector } from "react-redux";
import { RootState } from "./Application/Application";
import Login from "./Components/Login"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Orders from './Components/Orders/Orders';
import OrderDetails from './Components/Orders/OrderDetails';
import GrayGoods from "./Components/Pricing/GrayGoods";
import { initOrderData } from "./Application/UseCases/Init/initOrderData";
import { initPartnerData } from "./Application/UseCases/Init/initPartnerData";

// Styles
import styles from './App.module.scss';

function App() {
  const token = useSelector((state: RootState) => state.Login.sessionToken);
  const partnersKey = useSelector((state: RootState) => state.Login.partnersKey);

  if (token && partnersKey) {
    initOrderData({ partnersKey: partnersKey });
    initPartnerData({ partnersKey: partnersKey });
  }

  return <Router basename="/index.html" >
    {token &&
      <div className={styles.wrapper}>
        <div className={styles.overlay}></div>
        <div className={styles.App}>
          <MainNavigation />
          <div className={styles.MainContent}>
            <Switch>
              <Route path="/orderDetails/:key" component={OrderDetails} />
              <Route exact path="/" render={() => (
                <Redirect to="/orders" />
              )} />
              <Route exact path="/orders" component={Orders} />
              <Route exact path="/prices" component={GrayGoods} />
            </Switch>
          </div>
        </div>
      </div>
    }
    {!token && <Login></Login>}
  </Router>
}

export default App;