export enum OrderStatusId {
  PendingReview = "PendingReview",
  ApprovedPendingPayment = "ApprovedPendingPayment",
  ReadyForManufacturing = "ReadyForManufacturing",
  NeedsAttention = "NeedsAttention",
  Cancelled = "Cancelled",
  ShippedToPartner = "ShippedToPartner",
  Complete = "Complete"
}

export interface OrderStatusData {
  id?: string
  orderStatusId: OrderStatusId
  fixupsAllowed: boolean
  displayNames: {
    general: string
    coach: string
    partner: string
  }
  displayOrder: number
}
