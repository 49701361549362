import { Product } from '../Application/Domain/Product/Product';
import { useRef } from 'react';
import DisplayRosterGrid from './Roster/DisplayRosterGrid';
import { thumbnailUrlCxRenderUrl } from '../Utility/image';

// Styles
import styles from './ReviewItem.module.scss';

interface Props {
  product: Product;
  partner: any;
}

const ReviewItem = (props: Props) => {
  const product = props.product;
  const partner = props.partner;
  const designStyle = product.DesignStyle;
  const designStyleName = designStyle ? designStyle.Name : '';
  const grayGood = product.GrayGood;
  const grayGoodName = grayGood ? grayGood.Name : '';
  const price = product.Price;
  const rosterItems = product.RosterItems;
  let rosterItemsCount: number = product.RosterItems.length;
  const img: any = useRef([]);

  function handleOnLoad(e) {
    const imgElement = e.target;
    imgElement.parentElement.firstElementChild.style.display = 'none';
  }

  function onImgError(e, index) {
    const ele = img?.current[index];
    ele.style.margin = "3em";
    ele.style.width = "40px";
    ele.style.height = "40px";

    const imgElement = e.target;
    imgElement.onerror = "";
    imgElement.parentElement.firstElementChild.style.display = 'none';
    imgElement.src = '/image_logo.svg';
    return true;
  }

  return (
    <div className={styles.reviewItem}>
      <div className={styles.itemHeader}>
        <div className={styles.grayGood}>{grayGoodName}</div>
        <div className={styles.totalItems}>{rosterItemsCount} items</div>
        <div className={styles.price}>${price} Each</div>
        <div className={styles.subTotalLabel}>SubTotal: <span className={styles.subTotalValue}>
          ${Number.isInteger(product.TotalPrice) ? product.TotalPrice : product.TotalPrice.toFixed(2)}</span>
        </div>
      </div>
      <div className={styles.designStyle}>{designStyleName}</div>
      <div className={styles.itemNumber}>Item Number: {product.ItemNumber}</div>
      <div className={styles.body}>
        <div className={styles.bodyColumnOne}>
          <div className={styles.thumbsContainer}>
            <div className={styles.spinnerContainer}>
              <span className={styles.thumbLoading + ' fas fa-circle-notch fa-spin'}></span>
            </div>
            <img
              ref={el => img.current[0] = el}
              onLoad={handleOnLoad}
              onError={(e) => onImgError(e, 0)}
              src={thumbnailUrlCxRenderUrl({
                Size: 150,
                Camera: 'Front',
                DocId: product.SavedDocId,
                cadworxSessionId: partner.cadworxSessionId,
                type: "img"
              })} width={150} height={150} alt="front" />
            <img
              ref={el => img.current[1] = el}
              onLoad={handleOnLoad}
              onError={(e) => onImgError(e, 1)}
              src={thumbnailUrlCxRenderUrl({
                Size: 150,
                Camera: 'Back',
                DocId: product.SavedDocId,
                cadworxSessionId: partner.cadworxSessionId,
                type: "img"
              })} width={150} height={150} alt="back" />
          </div>
        </div>
        <div className={styles.rosterGridTable}>
          <DisplayRosterGrid className={styles.columnTwo} rosterItems={rosterItems} product={product} />
        </div>
      </div>
      <div className={styles.rosterTotalContainer}>
        <div className={styles.bodyColumnOne}>
          <div className={styles.rContainer}></div>
          <div className={styles.rContainer}></div>
        </div>
        <div className="roster-total">Roster Total: {rosterItemsCount}</div>
      </div>
    </div>
  );
};

export default ReviewItem;
