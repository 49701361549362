import { useState, useEffect } from "react";

const setRange = (data, rows) => {
    const range = [];
    const num = Math.ceil(data.length / rows);

    for (let i = 1; i <= num; i++) {
        range.push(i);
    }

    return range;
};

const setData = (data, page, rows) => {
    return data.slice((page - 1) * rows, page * rows);
};

const useTable = (data, page, rowsPerPage, sortBy) => {
    const [tableRange, setTableRange] = useState([]);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const range = setRange(data, rowsPerPage);
        setTableRange([...range]);

        const slice = setData(data.sort(sortBy.fn), page, rowsPerPage);
        setItems([...slice]);
    }, [data, setTableRange, page, setItems]);

    return { items, range: tableRange };
};

export default useTable;