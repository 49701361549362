import { UseCase } from '../../TypesAndInterfaces/UseCase';
import {app} from "../../../index";
import { getPartnerSessionData } from "./helper/getPartnerSessionData"

declare const Cx ;

export interface RequestPhoneCodeOptions {
}

export const partnerHasSessionUseCase: UseCase = {
  type: 'PARTNER_HAS_SESSION_FROM_TOKEN',

  run: async (options: RequestPhoneCodeOptions) => {

      const token = localStorage.getItem('steamspartner.sessionToken') || ''
      let coachSessionData ;
      if(token)
      {
        coachSessionData = await getPartnerSessionData(token);
        return coachSessionData ;
      }
      else {
        return Promise.reject("No token found on app data")
      }
  }
};

export function partnerHasSession(options: RequestPhoneCodeOptions) {
  app.runUseCase( partnerHasSessionUseCase, options );
}
