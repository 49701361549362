import { Product } from "../../Application/Domain/Product/Product";
import { ROSTER_FIELD_CA } from "../../Application/Domain/Roster/RosterFieldMap";

export interface RosterColumnEditor {
  options?: any;
  type: string;
}

export interface RosterColumn {
  editor: RosterColumnEditor;
  field: string;
  header: string;
  id: string;
}

export const getColumnConfig = (product: Product): RosterColumn[] => {
  // CA is an optional decoration area
  const caFieldMap = product.RosterFieldMap.find((item) => {
    return item.RosterField === ROSTER_FIELD_CA;
  });
  // If a CA field map is present we show the column
  const caOptions = caFieldMap ? [
    { text: ' ', value: ' '},
    { text: 'A', value: 'A'},
    { text: 'C', value: 'C'}
  ] : undefined;
  // Backward compat: old saved / submitted orders do not have this
  const sizeOptions = (product.RosterSizeOptions || []).map((size) => {
    return { text: size, value: size };
  });

  const columns: RosterColumn[] = [
    {
      editor: {
        type: 'text'
      },
      field: 'Name',
      header: 'Name',
      id: 'name'
    },
    {
      editor: {
        type: 'number'
      },
      field: 'Number',
      header: 'Number',
      id: 'number'
    },
    {
      editor: {
        type: 'number'
      },
      field: 'Quantity',
      header: 'Quantity',
      id: 'quantity'
    }
  ];

  if (caOptions) {
    columns.push({
      editor: {
        options: caOptions,
        type: 'select'
      },
      field: 'CA',
      header: 'C/A',
      id: 'ca'
    });
  }

  if (sizeOptions.length > 0) {
    // Insert after Number column
    columns.splice(2, 0, {
      editor: {
        options: sizeOptions,
        type: 'select'
      },
      field: 'Size',
      header: 'Size',
      id: 'size'
    });
  }

  return columns;
}