export function getImageURL(docId: string, w, h) {
  return `http://cxcore-ddteams.azurewebsites.net/Core2/Doc3D/Thumbnail?{"config":"ddteams","model":{"Doc":{"Id":"${docId}"},"Height":${h},"Width":${w}},"log":{"Environment":null,"ServerAccessToken":null},"ident":{"SessionId":"17C9C329-A54A-444D-9C65-4E1488DE0BBC","SuperUser":true}}`;
}

export interface CxRenderThumbnail {
  DocId: string;
  Size: number;
  Camera: string;
  cadworxSessionId: string;
  type: "base64" | "img"
}

export function thumbnailUrlCxRenderUrl(model: CxRenderThumbnail) {
  return `https://cx-3d-render.azureedge.net/render?docId=${model.DocId}&cameraState=${model.Camera}&cadworxSessionId=${model.cadworxSessionId}&type=${model.type}`;
};