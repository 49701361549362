import {STeams} from "../../ExternalAPI/STeams";
import {UseCase} from "../TypesAndInterfaces/UseCase";
import {app} from "../../index";

export type showOrdersByStatusResult = {
  orders: [];
  page: number;
  pageSize: number;
  statusId?: string;
}

async function action(options: showOrdersByStatusUseCaseOptions):Promise<showOrdersByStatusResult> {
  const payload = {
    partnersKey: options.partnersKey,
    page: 0,
    pageSize: 0, // Get all for now
    statusId: options.statusId || ""
  };

  const orders = await STeams.getOrdersByPartner(payload);
  return { ...payload, orders: orders };
}

export const showOrdersByStatusUseCase:UseCase = {
  type: 'showOrdersByStatus',
  run: action
};

interface showOrdersByStatusUseCaseOptions {
  statusId: string,
  partnersKey
}

export async function showOrdersByStatus(options: showOrdersByStatusUseCaseOptions){
  await app.runUseCase(showOrdersByStatusUseCase, options);
}

