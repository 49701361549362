export const ROSTER_FIELD_CA = 'CA';
export const ROSTER_FIELD_NAME = 'Name';
export const ROSTER_FIELD_NUMBER = 'Number';

// Use to determine what decoration areas are linked to a roster field
export interface RosterFieldMap {
  DecorationAreaIds: string[]; // e.g. ['Sleeve-Number-1', 'Sleeve-Number-2', 'Back-Number']
  DecorationAreaNames: string[]; // e.g. ['Sleeve-Number-1', 'Sleeve-Number-2', 'Back-Number']
  RosterField: string; // e.g. 'Number';
}

export function getRosterFieldMapFromCanvas(canvasId: string): RosterFieldMap[] {
  const fields = {};

  const items: RosterFieldMap[] = [];

  for (const key in fields) {
    items.push({
      DecorationAreaIds: fields[key],
      DecorationAreaNames: fields[key],
      RosterField: key
    });
  }

  return items;
}