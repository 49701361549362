import { Network } from "../Utility/Network";

export namespace STeams {

  const API_URL = process.env.REACT_APP_STEAMS_API_URL || 'https://ddbackend-staging.azurewebsites.net/api';

  export const getOrders = async () => {
    const orderData = await Network.get({
      endpoint: `${API_URL}/orders/readAll`
    });

    return orderData.Order;
  };

  interface ordersByPartnerOptions {
    partnersKey: string,
    page?: number,
    pageSize?: number,
    statusId?: string
  }

  interface accessByPhoneOptions {
    phone: string
  }

  interface VerifyPhoneCodePayload {
    phone: string,
    code: string
  }

  export const partnerRequestAccessByPhone = async (payload: accessByPhoneOptions) => {
    const resp = await fetch(`${API_URL}/auth2/partner/phone/requestForAccess`, {
      body: JSON.stringify(payload),
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    });

    console.log('partnerRequestAccessByPhone API result', resp);

    //TODO: Don't we want to know total on server?
    return resp.json();
  };

  export const verifyPhoneCode = async (payload: VerifyPhoneCodePayload) => {
    const resp = await fetch(`${API_URL}/auth2/partner/phone/verifyCode`, {
      body: JSON.stringify(payload),
      // credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    });
  
    // The use case itself should just fail here I believe.
    return resp.json();
  };

  export const logOut = async(sessionToken: string) => {
    const payload = {
      sessionToken
    };
  
    const logoutResult = await fetch(`${API_URL}/auth2/partner/logout`, {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    });
  
    if (logoutResult.ok) {
      const jsonResp = await logoutResult.json();
      return jsonResp;
    }
  
    return Promise.reject('Problem occurred during logout.');
  }
  
  export async function partnerHasSession(sessionToken: string) {
    const payload = {
      sessionToken
    };
  
    const hasSessionResp = await fetch(`${API_URL}/auth2/partner/hasSession`, {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    });
  
    if (hasSessionResp.ok) {
      const jsonResp = await hasSessionResp.json();
      return jsonResp;
    }
  
    return Promise.reject('Problem occurred when requesting session.');
  }
  
  export const getOrdersByPartner = async (payload: ordersByPartnerOptions) => {
    const orderData = await Network.get({
      endpoint: `${API_URL}/orders/readByPartner`,
      payload: payload
    });

    console.log('readByPartner API result', orderData);

    //TODO: Don't we want to know total on server?
    return orderData.items;
  };

  export const getAllOrderStatuses = async () => {
    const orderData = await Network.get({
      endpoint: `${API_URL}/orderStatuses/readAll`
    });

    return orderData.items;
  }

  export const createPaymentIntent = async (orderKey: string) => {
    const result = await Network.post({
      endpoint: `${API_URL}/createPaymentIntent`,
      payload: {
        items: [{ orderKey: orderKey }]
      }
    });

    return result;
  }

  export const getOrdersReadByKeyUrl = async (orderKey: string) => {
    const result = await Network.get({
      endpoint: `${API_URL}/orders/readByKey`,
      payload: {
        key: orderKey
      }
    });


    return { order: result?.items.Order, orderStatus: result?.items.OrderStatus }
  }

  export const getPartnerByKey = async (partnerKey: string) => {
    const result = await Network.get({
      endpoint: `${API_URL}/partners/readByKey`,
      payload: {
        key: partnerKey
      }
    });

    return result;
  }

  export const getCoachDataReadByKeyUrl = async (key: string) => {
    const result = await Network.get({
      endpoint: `${API_URL}/coaches/readByKey`,
      payload: {
        key: key
      }
    });

    return result;
  }

  export const changeGrayGoodPrice = async (sportAssetsKey: string, partnersKey:string, id: string, price: number) => {
    const payload = {
      sportAssetsKey,
      partnersKey,
      id,
      price
    }

    const resp = await fetch(`${API_URL}/sportAssets/changeGrayGoodPrice`, {
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      mode: 'cors'
    });
    console.log('updateGrayGood API result', resp);
    return resp.json();
  }
}
