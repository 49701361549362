import {STeams} from "../../ExternalAPI/STeams";
import {UseCase} from "../TypesAndInterfaces/UseCase";
import {app} from "../../index";

interface changeGrayGoodPriceUseCaseOptions {
  partnersKey: string,
  sportAssetsKey: string,
  id: string,
  price: number
}

async function action(options: changeGrayGoodPriceUseCaseOptions):Promise<changeGrayGoodPriceUseCaseOptions> {

  await STeams.changeGrayGoodPrice(options.sportAssetsKey, options.partnersKey, options.id, options.price);
  return options ;
}

export const changeGrayGoodPriceUseCase:UseCase = {
  type: 'changeGrayGoodPrice',
  run: action
};

export async function changeGrayGoodPrice(options: changeGrayGoodPriceUseCaseOptions){
  await app.runUseCase(changeGrayGoodPriceUseCase, options);
}

