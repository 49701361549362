import { useState, useEffect } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { STeams } from "../../ExternalAPI/STeams";

// Styles
import styles from './CheckoutForm.module.scss';

export default function CheckoutForm(props: any) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    STeams.createPaymentIntent(props.orderKey)
      .then(result => {
        setClientSecret(result.clientSecret);
      });
  }, [props.orderKey]);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    setProcessing(true);

    if (!stripe)
      throw new Error('stripe undefined or null');

    if (!elements)
      throw new Error('elements undefined or null');

    const cardElement = elements.getElement(CardElement);
    if (!cardElement)
      throw new Error('cardElement undefined or null, expected to have value here.');

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement
      }
    });

    console.log('payload', payload);

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    }
    else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <form className={styles.form} id="payment-form" onSubmit={handleSubmit}>
      <CardElement className={styles.cardElement} id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
        className={styles.button}
      >
        <span id="button-text">
          {processing ? (
            <div className={styles.spinner} id="spinner"></div>
          ) : (
            "Pay now"
          )}
        </span>
      </button>

      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className={styles.cardError} role="alert">
          {error}
        </div>
      )}

      {/* Show a success message upon completion */}
      <p className={succeeded ? styles.resultMessage : `${styles.resultMessage} ${styles.hidden}`}>
        Payment succeeded, thank you!
      </p>
    </form>
  );
}