import { Product } from '../../Application/Domain/Product/Product';
import { RosterItem } from '../../Application/Domain/Roster/RosterItem';
import { getColumnConfig } from './columnConfigs';

import './DisplayRosterGrid.scss';

interface Props {
  className?: string;
  product: Product;
  rosterItems: RosterItem[];
}

const DisplayRosterGrid = (props: Props) => {
  let className = 'display-roster-grid';
  const columns = getColumnConfig(props.product);
  const rosterItems = props.rosterItems;

  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <table className={className}>
      <tbody>
        <tr>
          {columns.map(function (columnCfg) {
            return <th key={columnCfg.id}>{columnCfg.header}</th>;
          })}
        </tr>
        {rosterItems.map(function (item) {
          return <tr key={item.Id}>
            {columns.map(function (columnCfg) {
              return <td key={item.Id + columnCfg.id}>
                {item[columnCfg.field]}
              </td>
            })}
          </tr>
        })}
      </tbody>
    </table>
  );

}

export default DisplayRosterGrid;
