import { STeams } from "../../../../ExternalAPI/STeams";

export async function getPartnerSessionData(sessionToken: string) {
  const partnerHasSessionResult = await STeams.partnerHasSession(sessionToken);
  const hasSession = partnerHasSessionResult.hasSession;

  if (!hasSession){
    return Promise.reject('Partner does not have session');
  }

  const partner = partnerHasSessionResult.partner;

  return {
    partner,
    hasSession,
    sessionToken: partnerHasSessionResult.sessionToken
  };
}