
import { useEffect, useState } from "react";
import Button from "../Button";
import ReviewItem from "../ReviewItem";
import { useLocation, useParams, useHistory, withRouter } from "react-router-dom";
import { STeams } from "../../ExternalAPI/STeams";
import { useSelector } from "react-redux";
import { RootState } from "../../Application/Application";
import { getCustomerInfoReadByKey } from "../../Application/UseCases/getCustomerInfoReadByKey";
import { OrderStatusId } from "../../Application/Domain/Order";
import { onApproveAndPayClick, onPayClick, onReadyClick } from "./OrderActions";
import { prettyDateAndTime, addWorkDays } from '../../Utility/Date'
// Styles
import styles from './OrderDetails.module.scss';

function OrderDetails() {
  const currentOrderStatusId = useSelector((state: RootState) => state.Order.currentStatusId);
  let { key } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { orderData } = location.state || {};
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [orderStatusDisplayName, setOrderStatusDisplayName] = useState<any | null>(null);
  const [orderKey, setOrderKey] = useState<string | null>(null);
  const [order, setOrder] = useState({ orderState: [] });
  const [estimatedShipping, setEstimatedShipping] = useState(null);
  const partnersKey: any = useSelector((state: RootState) => state.Login.partnersKey);

  // TODO: Make base configurable
  const openOrderUrl = `https://cxcdndev.azureedge.net/ub2/edge/index.html?pk=${partnersKey}&app.orderId=${orderKey}&app.openAsCopy=true`;
  const customerInfo: any = useSelector((state: RootState) => state.Order.coachContactInfo);
  const partner: any = useSelector((state: RootState) => state.App.partnerData);

  useEffect(() => {
    STeams.getOrdersReadByKeyUrl(key)
      .then(
        (result) => {
          setIsLoaded(true);

          if (result.order && result.orderStatus) {
            const orderStatus = result.orderStatus.slice(-1)[0] ;
            const orderStatusDisplayName = orderStatus?.displayNames.general ;
            setOrderStatusDisplayName(orderStatusDisplayName);
            setOrderKey(result.order[0].ordersKey);
            setOrder(result.order[0]);
            getCustomerInfoReadByKey({
              coachesKey: result.order[0].coach.coachesKey,
              customerInfoKey: result.order[0].customerInfoKey
            });
            const selectedShippingDays = parseInt(result.order[0].orderSnippetData.shippingMethod.days);
            const orderStatusDate = orderStatusDisplayName.toUpperCase() == "READY FOR MANUFACTURING" ? addWorkDays(new Date(orderStatus?.date), selectedShippingDays) : new Date();
            const calculatedShipping = prettyDateAndTime(addWorkDays(orderStatusDate, 15));
            setEstimatedShipping(calculatedShipping);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [key]);

  if (error) {
    return <div className={styles.loadingText}>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div className={styles.loadingText}>Loading...</div>;
  } else if (orderData && order.orderState) {
    return (
      <>
        <div className={styles.header}>
          <Button
            className={styles.backButton}
            faIconCls="fa-caret-left"
            label="BACK"
            onClick={() => history.goBack()} />
        </div>
        <div className={styles.orderInfo}>
          <div className={styles.orderInfoText}>Order #:  </div>
          <span className={styles.orderNumber}><a href={openOrderUrl} target="_blank">{orderKey}</a></span>
          <span className={styles.orderStatus}>{orderStatusDisplayName}</span>          
        </div>
        <div className={styles.customerContainer}>
          <div className={styles.column}>
            <div className={styles.labelGroup}>
              <div className={styles.customerText}>Contact:</div>
              <div className={styles.customerText}>Organization:</div>
              <div className={styles.customerText}>Estimated Shipping:</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.customerText}>{customerInfo.name ? customerInfo.name : "--"}</div>
              <div className={styles.customerText}>{customerInfo.organizationName ? customerInfo.organizationName : "--"}</div>
              <div className={styles.customerText}>{estimatedShipping}</div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.labelGroup}>
              <div className={styles.customerText}>Email:</div>
              <div className={styles.customerText}>Phone:</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.customerText}>{customerInfo.email ? customerInfo.email : "--"}</div>
              <div className={styles.customerText}>{customerInfo.phone ? customerInfo.phone : "--"}</div>
            </div>
          </div>
          <div className={styles.column}>
            <div className={styles.labelGroup}>
              <div className={styles.customerText}>Address:</div>
            </div>
            <div className={styles.infoGroup}>
              <div className={styles.customerText}>{customerInfo.streetAddress ? customerInfo.streetAddress : '--'}
              </div>
              <div className={styles.customerText}>{(customerInfo.streetAddress && customerInfo.city && customerInfo.state && customerInfo.zip)
                ? customerInfo.city + ', ' + customerInfo.state + ' ' + customerInfo.zip : ''}
              </div>
            </div>
          </div>
        </div> 

        <div className={styles.priceContainter}>
          <div className={styles.column}>
            <div className={styles.prices}>PRICES <i className="icon fas fa-light fa-tag"></i></div>
          </div>
          <div className={styles.column}>
            <div className={styles.text}><span>Retail Price:</span> ${Number.isInteger(orderData.orderSnippetData.retailTotal) ?
              orderData.orderSnippetData.retailTotal : orderData.orderSnippetData.retailTotal.toFixed(2)}</div>
            <div className={styles.text}><span>Cost Price:</span> ${Number.isInteger(orderData.orderSnippetData.costTotal) ?
              orderData.orderSnippetData.costTotal : orderData.orderSnippetData.costTotal.toFixed(2)}</div>
          </div>
          <div className={styles.column}>
            <div className={styles.text}><span>Shipping Retail Price:</span> ${orderData.orderSnippetData.shippingMethod.retail}</div>
            <div className={styles.text}><span>Shipping Cost Price:</span> ${orderData.orderSnippetData.shippingMethod.cost}</div>
          </div>
          <div className={styles.column}>
            {(currentOrderStatusId === OrderStatusId.ApprovedPendingPayment) && <Button theme="green" className={styles.btn} label="Pay" faIconCls="fa-credit-card" handler={() => onPayClick(orderKey, currentOrderStatusId, partnersKey)} />}
            {(currentOrderStatusId === OrderStatusId.PendingReview) && <Button theme="green" label="Approve and Pay" className={styles.btn} faIconCls="fa-thumbs-up" handler={() => onApproveAndPayClick(orderKey, currentOrderStatusId, partnersKey)} />}
            {(currentOrderStatusId === OrderStatusId.ShippedToPartner) && <Button theme="green" label="Mark as Ready!" className={styles.btn} faIconCls="fa-image" handler={() => onReadyClick(orderKey, currentOrderStatusId, partnersKey)} />}
          </div>
        </div>
        <div className={styles.productsContainer}>
          {order.orderState.map((productData: any) => {
            return <ReviewItem
              key={productData.Id}
              product={productData}
              partner={partner?.Partner}
            />
          })}
        </div>
      </>
    );
  } else if (!orderData) {
    return <div className={styles.loadingText}>No order found...</div>;
  } else {
    // do nothing
  }
}

export default withRouter(OrderDetails);
