import axios from "axios";

export namespace Network {

  interface requestParams {
    endpoint: string,
    payload?: any
  }

  export const get = async ({endpoint, payload = {}}: requestParams) => {
    const params = new URLSearchParams(payload).toString();
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    let res = await axios.get(`${endpoint}?${params}`);
    let data = res.data;

    //res.status
    //Informational responses (100–199)
    //Successful responses (200–299)
    //Redirects (300–399)
    //Client errors (400–499)
    //Server errors (500–599)

    return data;
  };

  export const post = async ({endpoint, payload = {}}: requestParams) => {
    //const params = new URLSearchParams(payload).toString();
    let res = await axios.post(endpoint, payload);
    let data = res.data;

    //res.status
    //Informational responses (100–199)
    //Successful responses (200–299)
    //Redirects (300–399)
    //Client errors (400–499)
    //Server errors (500–599)

    return data;
  };

}
