import {createStore, combineReducers} from 'redux';
import {UseCaseResult} from "./TypesAndInterfaces/UseCaseResult";
import {USE_CASE_RESULT_TYPE} from "./UseCases/UseCase/UseCaseResultFactory";
import {UseCaseResultFactory} from "./UseCases/UseCase/UseCaseResultFactory";
import {UseCase} from "./TypesAndInterfaces/UseCase";
import {AppReducer, AppState} from "./Reducers/AppReducer";
import {OrderReducer, OrderState} from "./Reducers/OrderReducer";
import {LoginReducer, LoginState} from "./Reducers/LoginReducer";
import { GuiReducer, GuiState } from './Reducers/GuiReducer';

export interface RootState {
  App: AppState,
  Order: OrderState,
  Login: LoginState,
  Gui: GuiState
}

export function Application(){
  const rootReducer = combineReducers({
    App: AppReducer,
    Order: OrderReducer,
    Login: LoginReducer,
    Gui: GuiReducer
  });

  const store:any = createStore(rootReducer);

  const dispatch = function(data:UseCaseResult){
    console.log('dispatching use case result...', data);
    store.dispatch(data);
  };

  const dispatchSuccess = function(data:any){
    data.resultType = USE_CASE_RESULT_TYPE.SUCCESS;
    dispatch(UseCaseResultFactory(data));
  };

  const dispatchFailure = function(data:any){
    data.resultType = USE_CASE_RESULT_TYPE.FAILURE;
    dispatch(UseCaseResultFactory(data));
  };

  const dispatchStart = function(data:any) {
    data.resultType = USE_CASE_RESULT_TYPE.START;
    let result = UseCaseResultFactory(data);
    dispatch(result);
  };

  const dispatchUseCaseSuccess = function(useCase: UseCase, data: any){
    dispatchSuccess({
      useCase: useCase
      , type: useCase.type
      , data: data
    });
  };

  const dispatchUseCaseFailure = function(useCase: UseCase, reason: any){
    dispatchFailure({
      useCase: useCase
      , type: useCase.type
      , rejectionReason: reason
    });
  };

  const dispatchUseCaseStart = function(useCase: UseCase){
    dispatchStart({
      useCase: useCase
      , type: useCase.type
    });
  };

  return Object.freeze({

    dispatch(key: string, data: any){
      console.log('DEPRECIATED, DISPATCH THROUGH SPECIFIC FUNCTIONS ON APP');
      //dispatchSuccess({ type: key, data: data });
    },

    getReduxStore(){
      return store;
    },

    async runUseCase(useCase: UseCase, options:any = {}){
      Object.assign({ dispatchStart: true }, options);

      //if(useCase.checkConditions){
      //  let ruleResult = useCase.checkConditions(this.getReduxStore().getState(), options);
      //  if(!ruleResult.isAllowed){
      //    alert(ruleResult.failMessage); //TODO: dispatch Notification
      //    return;
      //  }
      //}
      //
      //if (useCase.checkValidators){
      //  let ruleResult = useCase.checkValidators(options);
      //  if(!ruleResult.isAllowed){
      //    alert(ruleResult.failMessage); //TODO: dispatch Notification
      //    return;
      //  }
      //}

      if(options.dispatchStart !== false)
        dispatchUseCaseStart(useCase);

      try {
        const useCaseResult = await useCase.run(options);
        dispatchUseCaseSuccess(useCase, useCaseResult);
      }
      catch(error){
        dispatchUseCaseFailure(useCase, error);

        //Propagate failure, we aren't handling/recovering, just dispatching action failure.
        //Leave it up to individual use cases how they handle rejections.
        return await Promise.reject(error);
      }

      //return useCase.run(options)
      //.then(function (useCaseResult:any = {}) {
      //  dispatchUseCaseSuccess(useCase, useCaseResult);
      //}, function(reason){
      //  dispatchUseCaseFailure(useCase, reason);
      //
      //  //Propagate failure, we aren't handling/recovering, just dispatching action failure.
      //  //Leave it up to individual use cases how they handle rejections.
      //  return Cx.reject(reason);
      //});
    }
  });
}

//We choose what to export in UseCases/index.ts
//export * from './lib/Application/UseCases';
