import { STeams } from "../../ExternalAPI/STeams";
import { UseCase } from "../TypesAndInterfaces/UseCase";
import { app } from "../../index";

export type showOrderByKeyResult = {
    order: {};
    orderStatus: {};
}

async function action(options: showOrderByKeyUseCaseOptions): Promise<showOrderByKeyResult> {
    const result = await STeams.getOrdersReadByKeyUrl(options.ordersKey);
    return { order: result.order, orderStatus: result.orderStatus };
}

export const showOrderByKeyUseCase: UseCase = {
    type: 'showOrderDetailsByKey',
    run: action
};

interface showOrderByKeyUseCaseOptions {
    ordersKey: string;
}

export async function showOrderByKey(options: showOrderByKeyUseCaseOptions) {
    await app.runUseCase(showOrderByKeyUseCase, options);
}
