import { UseCaseResult } from "../TypesAndInterfaces/UseCaseResult";
import { initOrderDataResult, initOrderDataUseCase } from "../UseCases/Init/initOrderData";
import { showOrdersByStatusResult, showOrdersByStatusUseCase } from "../UseCases/showOrdersByStatus";
import { showOrderByKeyResult, showOrderByKeyUseCase } from "../UseCases/showOrderByKey";
import { coachContactInfo, getCustomerInfoReadByKeyUseCase } from "../UseCases/getCustomerInfoReadByKey";
import { getAllOrdersKeyByStatusUseCase, ordersByStatusResult } from "../UseCases/getAllOrdersKeyByStatus";
import { lastPageByStatusResult, setLastPageUseCase } from "../UseCases/setLastPage";

export interface OrderState {
  initComplete: boolean;
  orders: [];
  page: number;
  pageSize: number;
  currentStatusId?: string;
  order: {};
  orderStatus: {};
  coachContactInfo: {};
  allOrderByStatus: [];
  lastPageByStatus: {};
}

const defaultOrderReducerData: OrderState = {
  initComplete: false,
  orders: [],
  page: 0,
  pageSize: 0,
  currentStatusId: undefined,
  order: {},
  orderStatus: {},
  coachContactInfo: {},
  allOrderByStatus: undefined,
  lastPageByStatus: {}
};

export const OrderReducer = function (state: OrderState = { ...defaultOrderReducerData }, useCaseResult: UseCaseResult) {
  state = Object.assign(state, { // Ensure we return a new state
    actionResult: useCaseResult
  });

  switch (useCaseResult.type) {
    case initOrderDataUseCase.type:
      const initOrderDataResult: initOrderDataResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          initComplete: true,
          orders: initOrderDataResult.orders,
          page: initOrderDataResult.page,
          pageSize: initOrderDataResult.pageSize,
          currentStatusId: initOrderDataResult.defaultStatusId
        });
      }
      break;

    case showOrdersByStatusUseCase.type:
      const showOrdersByStatusResult: showOrdersByStatusResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          orders: showOrdersByStatusResult.orders,
          page: showOrdersByStatusResult.page,
          pageSize: showOrdersByStatusResult.pageSize,
          currentStatusId: showOrdersByStatusResult.statusId
        });
      }
      break;

    case showOrderByKeyUseCase.type:
      const showOrderByKeyResult: showOrderByKeyResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          order: showOrderByKeyResult.order,
          orderStatus: showOrderByKeyResult.orderStatus
        });
      }
      break;

    case getCustomerInfoReadByKeyUseCase.type:
      const customerInfo: coachContactInfo = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          coachContactInfo: customerInfo,
        });
      }
      break;

    case getAllOrdersKeyByStatusUseCase.type:
      const resut: ordersByStatusResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {  
          allOrderByStatus: resut.allOrdersByStatus
        });
      }
      break;

    case setLastPageUseCase.type:
      const data: lastPageByStatusResult = useCaseResult.data;

      if (useCaseResult.success()) {
        return Object.assign({}, state, {
          lastPageByStatus: data
        });
      }
      break;

    default:
      return state;
  }

  return state;
};

